import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  LogoutOutlined,
  LoginOutlined,
  DownOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { Dropdown, Popover } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLegalResearcherChatsData } from "../../Api/Upload";
import { dmsStatus, signout } from "../../Api/Auth";

const SideBar = ({
  setshowModal1,
  setshowModal,
  isAuthenticated,
  data,
  tourRef2,
  tourRef1,
  setOpen,
  nextCloud,
  dmsStatus,
  signout,
  selectedConversation,
  setselectedConversation,
  refreshConversations,
  setrefreshConversations,
}) => {
  const history = useHistory();
  const [conversationList, setconversationList] = useState([]);

  useEffect(() => {
    if (
      localStorage.getItem("isTourDone") === null &&
      history.location.pathname === "/dashboard"
    ) {
      setOpen(true);
    }
  }, [history, setOpen]);

  useEffect(() => {
    if (isAuthenticated) {
      getLegalResearcherChatsData().then((res) => {
        if (res !== null) {
          if (res.success === true) {
            setconversationList(res.user_query);
          } else {
            setconversationList([]);
          }
        } else {
          setconversationList([]);
        }
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (refreshConversations) {
      getLegalResearcherChatsData().then((res) => {
        if (res !== null) {
          if (res.success === true) {
            setconversationList(res.user_query);
          } else {
            setconversationList([]);
          }
        } else {
          setconversationList([]);
        }
      });
      setrefreshConversations(false);
    }
  }, [refreshConversations, setrefreshConversations]);

  useEffect(() => {
    if (!isAuthenticated) {
      if (window.location.pathname.includes("education")) {
        history.push("/education");
      } else {
        history.push("/");
      }
    }
  }, [isAuthenticated, history]);

  const items = [
    {
      label: "Rename",
      key: "1",
    },
    {
      label: "Delete",
      key: "2",
    },
  ];
  const menuProps = {
    items,
  };

  //prompts data

  return (
    <div>
      <div className="dashboard-sidebar-wrapper pt-5" id="sidebar">
        <div className="brand-logo px-6 text-left">
          <Link to="#!">
            <img
              src="/assets/image/logoBlack.png"
              alt=""
              style={{ width: "100%", height: "40px" }}
            />
          </Link>
        </div>
        <div
          className="mt-6 mb-3 px-6 uploadButn"
          id="#uploadButn"
          style={{ height: "35px", overflow: "auto" }}
        >
          <button
            to="create-job"
            ref={tourRef1}
            className="btn btn-primary font-size-2 font-weight-normal w-100 text-uppercase"
            style={{ height: "35px" }}
            onClick={() => {
              setselectedConversation(null);
            }}
          >
            <span className="mr-2 d-inline-block ">+</span>
            New Chat
          </button>
        </div>
        <div ref={tourRef2} className="position-relative">
          <ul
            className="list-unstyled ml-0 dashboard-layout-sidebar sidebar-content-height "
            style={{ height: "calc(100vh - 300px)" }}
          >
            {conversationList.map((item, index) => (
              <li
                onClick={() => {
                  setselectedConversation(item.query_id);
                }}
                className={
                  " px-2 " +
                  (item.query_id === selectedConversation ? "active" : "")
                }
                key={index}
              >
                <Link
                  to="#!"
                  className="px-6 py-1 my-0 font-size-3 font-weight-semibold flex-y-center "
                  style={{ borderRadius: "10px" }}
                  onClick={async (e) => {
                    e.preventDefault();
                  }}
                  title={item.query_name}
                >
                  {item.query_name.length > 20
                    ? item.query_name.slice(0, 20) + "..."
                    : item.query_name}
                  <Dropdown trigger={["click"]} menu={{ items }}>
                    <span className="ml-auto ">
                      <i className="fa fa-ellipsis-h ml-auto font-size-4 text-black-50"></i>
                    </span>
                  </Dropdown>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <ul className="list-unstyled dashboard-layout-sidebar position-sidebar">
          <li onClick={() => {}}>
            <Link
              to="#!"
              className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
              onClick={(e) => {
                e.preventDefault();
                if (isAuthenticated) {
                  setshowModal1(true);
                } else {
                  setshowModal(true);
                }
              }}
            >
              <i className="fas fa-tags mr-7" style={{ width: "16px" }}></i>
              Pricing
            </Link>
          </li>
          <li className="position-relative" onClick={() => {}}>
            <Popover
              placement="right"
              style={{ padding: "0px" }}
              content={
                <div className="" style={{ width: "140px" }}>
                  <ul className="list-unstyled dashboard-layout-sidebar mb-0 pb-0">
                    <li onClick={() => {}}>
                      <Link
                        to="/prompts"
                        className="px-5  py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
                      >
                        <i
                          className="fas fa-lightbulb mr-4"
                          style={{ width: "16px" }}
                        ></i>
                        Prompts
                      </Link>
                    </li>
                    <li onClick={() => {}}>
                      <Link
                        to="/faqs"
                        className="px-3  my-2 font-size-3 font-weight-semibold flex-y-center"
                      >
                        <i
                          className="fas fa-info-circle mr-7"
                          style={{ width: "16px" }}
                        ></i>
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </div>
              }
            >
              <Link
                to="#!"
                className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
              >
                <i
                  className="fas fa-question-circle mr-7"
                  style={{ width: "16px" }}
                ></i>
                Help
              </Link>
            </Popover>
            <i
              className="fas fa-caret-right  mr-5"
              style={{
                width: "16px",
                position: "absolute",
                right: "0px",
                top: "8px",
              }}
            ></i>
          </li>
          <li onClick={() => {}} style={{ display: "none" }}>
            <Dropdown menu={menuProps}>
              <Link
                to="#!"
                className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
              >
                <i
                  className="fas fa-shield-alt mr-7"
                  style={{ width: "16px" }}
                ></i>
                Go Private <DownOutlined className="ml-auto" />
              </Link>
            </Dropdown>
          </li>
          {isAuthenticated ? (
            <>
              <li onClick={() => {}}>
                <Link
                  to="#!"
                  className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
                  onClick={(e) => {
                    e.preventDefault();
                    if (nextCloud.is_logged_in) {
                      window.open(
                        "https://core.sigmatext.ai/dms/apps/dashboard/",
                        "_blank"
                      );
                    } else {
                      window.open(nextCloud.login_url, "_blank");
                    }
                    dmsStatus(true);
                  }}
                >
                  <CloudUploadOutlined
                    className="mr-7 font-size-4"
                    style={{ width: "16px" }}
                  />
                  Drive
                </Link>
              </li>
              <li onClick={() => {}}>
                <Link
                  to="#!"
                  className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
                  onClick={(e) => {
                    e.preventDefault();
                    signout();
                  }}
                >
                  <LogoutOutlined
                    className="mr-7 font-size-4"
                    style={{ width: "16px" }}
                  />
                  Signout
                </Link>
              </li>
            </>
          ) : (
            <li onClick={() => {}}>
              <Link
                to="#!"
                className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
                onClick={(e) => {
                  e.preventDefault();
                  setshowModal(true);
                }}
              >
                <LoginOutlined
                  className="mr-7 font-size-4"
                  style={{ width: "16px" }}
                />
                Signin
              </Link>
            </li>
          )}
          <li className="bg-light  text-primary">
            <Link
              to="/"
              className="px-6 py-3 my-2 font-size-3 font-weight-bold flex-y-center text-primary"
              onClick={(e) => {
                e.preventDefault();
                if (window.location.href.includes("/education")) {
                  history.push("/education");
                } else if (window.location.pathname === "/dashboard") {
                  history.push("/");
                } else {
                  history.push("/dashboard");
                }
              }}
            >
              <i
                className="fas fa-arrow-left mr-7 font-weight-bold text-primary"
                style={{ width: "16px" }}
              ></i>
              {window.location.pathname === "/dashboard" ? "Home" : "Go Back"}
            </Link>
          </li>
        </ul>
      </div>
      <Link
        className="sidebar-mobile-button"
        data-toggle="collapse"
        to="#!"
        role="button"
        aria-expanded="false"
        aria-controls="sidebar"
      >
        <i className="icon icon-sidebar-2"></i>
      </Link>
    </div>
  );
};
SideBar.propTypes = {
  isAuthenticated: PropTypes.any,
  nextCloud: PropTypes.any,
  signout: PropTypes.func,
  dmsStatus: PropTypes.func,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  nextCloud: state.auth.nextCloud,
});
export default connect(mapStateToProps, {
  dmsStatus,
  signout,
})(SideBar);
