import { Card, message, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SaveOutlined, CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Recorder from "../Recorder";
import {
  editDictationChatsData,
  getSingleDictationChatsData,
  saveDictationChatsData,
} from "../../../../Api/Upload";

const Index = ({
  tourRef4,
  selectedConversation,
  setselectedConversation,
  setrefreshConversations,
}) => {
  useEffect(() => {
    return () => {
      setselectedConversation(null);
    };
  }, [setselectedConversation]);

  const [generateText, setgenerateText] = useState("");
  const [loading, setloading] = useState("");

  useEffect(() => {
    if (selectedConversation === null) {
      setgenerateText("");
    }
  }, [selectedConversation]);

  const onTranslation = (text) => {
    setgenerateText((prev) => prev + " " + text);
  };

  useEffect(() => {
    if (selectedConversation !== null) {
      getSingleDictationChatsData(selectedConversation).then((res) => {
        if (res !== null) {
          if (res.success === true) {
            if (res.user_dictate.length > 0) {
              setgenerateText(res.user_dictate[0].dictate);
            }
          } else {
            setgenerateText("");
          }
        } else {
          setgenerateText("");
        }
      });
    }
  }, [selectedConversation]);

  const [rows, setRows] = useState(1);
  const textRef = useRef(null); // Reference to the hidden textarea

  const calculateRows = () => {
    if (textRef.current) {
      const lineHeight = 24; // Adjust based on your CSS line-height
      const scrollHeight = textRef.current.scrollHeight;
      const calculatedRows = Math.ceil(scrollHeight / lineHeight);
      setRows(calculatedRows);
    }
  };

  useEffect(() => {
    calculateRows();
  }, [generateText]);

  return (
    <div className="dashboard-main-container mt-9 mt-lg-9" id="dashboard-body">
      <div className="container position-relative">
        <div className="row">
          <div className="col-11 m-auto heightFull">
            <div
              ref={tourRef4}
              className="col-12  px-8 inputBox position-relative text-center"
              style={{
                marginLeft: "-1rem",
              }}
            >
              <span style={{ width: "100px", background: "transparent" }}>
                <Recorder
                  onTranscription={onTranslation}
                  fontSize="28px"
                  style={{ height: "80px", width: "80px" }}
                />
              </span>
            </div>
            <Card bodyStyle={{ paddingBottom: "4px" }}>
              <Tooltip title="Copy">
                <span
                  style={{
                    position: "absolute",
                    right: "calc(2% + 36px)",
                    cursor: "pointer",
                    background: "rgba(0,0,0,0.1)",
                    border: "thin solid rgba(0,0,0,0.05)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    verticalAlign: "middle",
                  }}
                  onClick={() => {
                    navigator.clipboard
                      .writeText(generateText)
                      .then(() => {
                        message.success("Text copied to clipboard");
                      })
                      .catch((err) => {
                        console.error("Failed to copy text: ", err);
                      });
                  }}
                >
                  <CopyOutlined
                    className="text-primary"
                    style={{
                      top: "25%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      position: "relative",
                      fontSize: "16px",
                    }}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Save">
                <span
                  style={{
                    position: "absolute",
                    right: "2%",
                    cursor: "pointer",
                    background: "rgba(0,0,0,0.1)",
                    border: "thin solid rgba(0,0,0,0.05)",
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    verticalAlign: "middle",
                  }}
                  onClick={() => {
                    if (!loading && generateText !== "") {
                      setloading(true);
                      if (selectedConversation === null) {
                        saveDictationChatsData({
                          dictate: generateText,
                        }).then((res) => {
                          if (res !== null) {
                            if (res.success === true) {
                              setrefreshConversations(true);
                              setselectedConversation(res.user_dictate.id);
                              message.success("Text saved successfully");
                            }
                          }
                          setloading(false);
                        });
                      } else {
                        editDictationChatsData({
                          dictate: generateText,
                          id: selectedConversation,
                        }).then((res) => {
                          if (res !== null) {
                            if (res.success === true) {
                              message.success("Text saved successfully");
                            }
                          }
                          setloading(false);
                        });
                      }
                    }
                  }}
                >
                  {loading ? (
                    <LoadingOutlined
                      className="text-primary"
                      style={{
                        top: "25%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        position: "relative",
                        fontSize: "16px",
                      }}
                    />
                  ) : (
                    <SaveOutlined
                      className="text-primary"
                      style={{
                        top: "25%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        position: "relative",
                        fontSize: "16px",
                      }}
                    />
                  )}
                </span>
              </Tooltip>
              <div className="chat-history p-0">
                <textarea
                  ref={textRef}
                  value={generateText}
                  readOnly
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    height: "auto",
                    lineHeight: "24px", // Match the actual textarea's line-height
                    overflow: "hidden",
                    whiteSpace: "pre-wrap",
                    fontSize: "16px",
                    padding: "8px",
                  }}
                />
                <textarea
                  value={generateText}
                  rows={rows} // Dynamically calculated rows
                  onChange={(e) => setgenerateText(e.target.value)}
                  style={{
                    lineHeight: "24px",
                    width: "100%",
                    fontSize: "16px",
                    padding: "8px",
                    border: "thin solid rgba(0,0,0,0.05)",
                    borderRadius: "10px",
                    outline: "none",
                    resize: "none",
                  }}
                  placeholder="Your generated text will appear here..."
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

Index.propTypes = {
  isAuthenticated: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(Index);
