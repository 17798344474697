import React from "react";
import { Card, Row, Col } from "antd";
import {
  ArrowRightOutlined,
  FormOutlined,
  MessageOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const data = [
  {
    title: "Chat with your files",
    description: "Chat with the uploaded documents.",
    icon: <MessageOutlined />, // Replace with a custom icon
    bgColor: "#f5f0ff",
    url: "/dashboard/chat",
    active: true,
  },
  {
    title: "Draft you document",
    description: "Create text for your document efficiently.",
    icon: <FormOutlined />, // Replace with a custom icon
    bgColor: "#fff5f5",
    url: "/dashboard/text-generation",
    active: true,
  },
  {
    title: "Dictation",
    description: "Turn your voice into Documents.",
    icon: <FormOutlined />, // Replace with a custom icon
    bgColor: "#DFF5E4",
    url: "/dashboard/dictation",
    active: true,
  },
  {
    title: "Legal Researcher",
    description: "Research about anything you want.",
    icon: <FileSearchOutlined />, // Replace with a custom icon
    bgColor: "#D3D3D3",
    active: false,
    url: "#!",
  },
  // Add more items here
];

const Index = () => {
  const history = useHistory();

  return (
    <div className="dashboard-main-container mt-9 mt-lg-9" id="">
      <div className="container position-relative">
        <Card>
          <Row gutter={[16, 16]}>
            {data.map((item, index) => (
              <Col key={index} xs={24} sm={12} lg={8} xl={8}>
                <Card
                  hoverable
                  style={{
                    backgroundColor: item.bgColor,
                    borderRadius: "10px",
                    textAlign: "left",
                    minHeight: "160px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                  onClick={(e) => {
                    history.push(item.url);
                  }}
                >
                  <div>
                    <div style={{ fontSize: "30px", marginBottom: "15px" }}>
                      {item.icon}
                    </div>
                    <h5 className="mb-5 font-weight-bold">{item.title}</h5>
                    <p
                      style={{
                        color: "#666",
                        marginBottom: "10px",
                        fontSize: "18px",
                        lineHeight: "28px",
                      }}
                    >
                      {item.description}
                    </p>
                  </div>
                  {item.active ? (
                    <ArrowRightOutlined
                      style={{ fontSize: "20px", color: "#666" }}
                    />
                  ) : (
                    "Coming Soon..."
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Index;
