import React, { useEffect, useState, useRef } from "react";
import { AudioOutlined } from "@ant-design/icons";
import Recorder from "recorder-js";

function AudioRecorderAndPlayer({
  onTranscription,
  fontSize = "18px",
  style = {},
  onStart = () => {},
  onStop = () => {},
  border = true,
}) {
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null); // Recorder.js instance
  const audioContextRef = useRef(null); // Web Audio API Context
  const streamRef = useRef(null); // Reference to the MediaStream
  const socketRef = useRef(null); // WebSocket reference
  const [textExtracted, settextExtracted] = useState("");
  const chunkIntervalRef = useRef(null); // To store the interval ID

  const updateText = useRef(null);
  updateText.current = (text) => {
    if (text !== "") onTranscription(text);
  };

  useEffect(() => {
    updateText.current(textExtracted);
  }, [textExtracted]);

  useEffect(() => {
    // Initialize WebSocket
    const initWebSocket = () => {
      const ws = new WebSocket("wss://core.sigmatext.ai/ws/transcription");
      socketRef.current = ws;

      ws.onopen = () => {
        console.log("WebSocket connection established.");
      };

      ws.onmessage = (event) => {
        settextExtracted((prev) => event.data);
        console.log("Message from server:", event.data);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = () => {
        console.log("WebSocket closed.");
        if (
          window.location.pathname === "/dashboard/chat" ||
          window.location.pathname === "/text-generation" ||
          window.location.pathname === "/dashboard/dictation"
        ) {
          initWebSocket();
        }
      };

      return ws;
    };

    initWebSocket();

    return () => {
      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        socketRef.current.close();
      }
    };
  }, []);

  const startRecording = async () => {
    onStart();
    if (isRecording) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      audioContextRef.current = audioContext;

      const recorderInstance = new Recorder(audioContext);
      await recorderInstance.init(stream);

      setRecorder(recorderInstance);
      setIsRecording(true);

      recorderInstance
        .start()
        .then(() => console.log("Recording started"))
        .catch((err) => console.error("Error starting recording:", err));

      // Start collecting audio chunks every 5 seconds
      chunkIntervalRef.current = setInterval(() => {
        recorderInstance
          .stop()
          .then(({ blob }) => {
            console.log("Audio chunk size:", blob.size);
            const wavBlob = new Blob([blob], { type: "audio/wav" });
            if (socketRef.current?.readyState === WebSocket.OPEN) {
              console.log("Audio chunk sent to WebSocket.");
              socketRef.current.send(wavBlob);
            } else {
              console.warn("WebSocket not open. Failed to send chunk.");
            }
            // Restart recorder for the next chunk
            recorderInstance
              .start()
              .catch((err) =>
                console.error("Error restarting recording:", err)
              );
          })
          .catch((err) => console.error("Error stopping recorder:", err));
      }, 3000);
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const stopRecording = async () => {
    onStop();
    if (!isRecording) return;

    setIsRecording(false);

    // Clear the interval
    if (chunkIntervalRef.current) {
      clearInterval(chunkIntervalRef.current);
      chunkIntervalRef.current = null;
    }

    // Stop the recorder
    if (recorder) {
      recorder
        .stop()
        .then(({ blob }) => {
          console.log("Final audio chunk size:", blob.size);
          if (blob.size > 0) {
            const wavBlob = new Blob([blob], { type: "audio/wav" });
            if (socketRef.current?.readyState === WebSocket.OPEN) {
              console.log("Final audio chunk sent to WebSocket.");
              socketRef.current.send(wavBlob);
            }
          }
        })
        .catch((err) => console.error("Error stopping recorder:", err));
    }

    // Stop all audio tracks to release the microphone
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }

    // Close the audio context
    if (audioContextRef.current) {
      await audioContextRef.current.close();
      audioContextRef.current = null;
    }
  };

  return (
    <span style={style}>
      <span
        onClick={() => {
          isRecording ? stopRecording() : startRecording();
        }}
        className="text-center"
        style={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
          background: isRecording ? "red" : "",
          border: border
            ? isRecording
              ? "1px solid red"
              : "1px solid"
            : "none",
          borderRadius: "100px",
        }}
      >
        {isRecording ? (
          <span
            className="control-button px-0 pb-0 "
            style={{
              padding: "0px",
              position: "relative",
              top: border ? "50%" : "67%",
              left: border ? "1%" : "-22%",
              transform: "translate(0%, -52%)",
            }}
          >
            <i
              className="fa fa-stop "
              style={{ fontSize: border ? fontSize : "14px", color: "white" }}
            />
          </span>
        ) : (
          <span
            className="control-button px-0 pb-0"
            style={{
              padding: "0px",
              position: "relative",
              top: border ? "50%" : "52%",
              left: border ? "1%" : "-21%",
              transform: "translate(0%, -52%)",
            }}
          >
            <AudioOutlined style={{ fontSize: fontSize }} />
          </span>
        )}
      </span>
    </span>
  );
}

export default AudioRecorderAndPlayer;
