import { Card, Dropdown, Input, Tag, Upload } from "antd";
import React, { useRef, useState } from "react";
import {
  SendOutlined,
  PaperClipOutlined,
  UploadOutlined,
  AntCloudOutlined,
  FileFilled,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import { AudioPlayer } from "react-audio-player-component";
import {
  checkProcceedFunc,
  getFilesForSelectionData,
  getResponseForQueryData,
  getTextGenerationChatHistoryData,
  s3upload,
  uploadToS3FinalizeFunc,
  uploadToS3InitFunc,
} from "../../../../Api/Upload";
import FileManager from "../FileManager";
import Recorder from "../Recorder";

const formatText = (text) => {
  text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

  const htmlString = text
    .replace(/\n/g, "<br />")
    .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") // Replace tab with four non-breaking spaces
    .replace(/ {2,}/g, (match) =>
      match
        .split("")
        .map(() => "&nbsp;")
        .join("")
    ); // Replace multiple spaces with non-breaking spaces
  return { __html: htmlString };
};

var promptTextGen = `Please ignore all previous instructions. You are an expert copywriter who writes detailed and thoughtful content.   I want you to write in the english language. Please use the details provided by the user. Please intersperse short and long sentences. Utilize uncommon terminology to enhance the originality of the content. Please format the content in a professional format. Do not self reference. Do not explain what you are doing.`;

const Index = ({
  tourRef4,
  selectedConversation,
  setselectedConversation,
  setrefreshConversations,
  refreshConversations,
  s3upload,
}) => {
  const [queryText, setqueryText] = useState("");
  const currentCursor = useRef(null);
  const [audioData, setAudioData] = useState(null);
  const [typing, settyping] = useState(null);
  const [conversationHistory, setconversationHistory] = useState([]);
  const [fileAnalyseState, setfileAnalyseState] = useState(0);

  const onTranslation = (text) => {
    setqueryText((prev) => prev + " " + text);
  };

  useEffect(() => {
    return () => {
      setselectedConversation(null);
    };
  }, [setselectedConversation]);

  useEffect(() => {
    if (selectedConversation === null) {
      setconversationHistory([]);
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (selectedConversation !== null) {
      getTextGenerationChatHistoryData(selectedConversation, 1).then((res) => {
        if (res !== null) {
          if (res.success === false) {
            setconversationHistory([]);
          } else {
            var data = res.conversation.reverse();
            var newSet = [];
            var isUser = true;
            data.forEach((ele) => {
              newSet.push({
                date: new Date(ele.created_at),
                hasFile: ele.file !== null,
                file_url: ele.s3_url,
                file_name: ele.file_name,
                file: ele.file,
                text: ele.user.is_bot
                  ? ele.text
                  : ele.audio_file !== null
                  ? ele.audio_file
                  : ele.text,
                isAudio: ele.audio_file !== null,
                mode: ele.user.is_bot ? "incoming" : "outgoing",
              });
              isUser = !isUser;
            });
            setconversationHistory(newSet);
          }
        } else {
          setconversationHistory([]);
        }
      });
    }
  }, [selectedConversation]);

  // useEffect(() => {
  //   if (currentCursor.current) {
  //     const inputElement = currentCursor.current.input;
  //     inputElement.setSelectionRange(queryText.length, queryText.length);
  //   }
  // }, [queryText]);

  const submitText = async () => {
    if (queryText !== "") {
      let qText = queryText;
      settyping(true);
      setqueryText("");
      if (mainFile !== null) {
        setfileAnalyseState(1);
        let file = mainFile;
        setmainFile(null);
        let path = "/";
        setselectedFile(null);
        let convo_data = [...conversationHistory];
        convo_data.push({
          hasFile: true,
          file: "currentNone",
          file_url: URL.createObjectURL(file),
          file_name: file.name,
          date: new Date(),
          text: qText,
          isAudio: false,
          mode: "outgoing",
        });
        setconversationHistory(convo_data);
        uploadToS3InitFunc({
          file_name: file.name,
          file_path: path + file.name,
        }).then(async (res) => {
          if (res !== null) {
            if (res.success === true) {
              let data = sessionStorage.getItem("file_url_data")
                ? JSON.parse(sessionStorage.getItem("file_url_data"))
                : [];
              data.push({
                file_id: res.file_url_data["file_id"],
                is_uploaded: 0,
              });
              sessionStorage.setItem("file_url_data", JSON.stringify(data));
              setconversationHistory((prev) => {
                prev = prev.map((ele) => {
                  if (ele.file === "currentNone") {
                    ele.file = res.file_url_data["file_id"];
                  }
                  return ele;
                });
                return prev;
              });
              await s3upload(
                file,
                res.file_url_data.s3_url_data.url,
                res.file_url_data.s3_url_data.fields.key,
                res.file_url_data.s3_url_data.fields["x-amz-algorithm"],
                res.file_url_data.s3_url_data.fields["x-amz-credential"],
                res.file_url_data.s3_url_data.fields["x-amz-date"],
                res.file_url_data.s3_url_data.fields["policy"],
                res.file_url_data.s3_url_data.fields["x-amz-signature"],
                res.file_url_data["file_id"],
                file.name,
                true
              );
              let interval = setInterval(() => {
                let data = sessionStorage.getItem("file_url_data")
                  ? JSON.parse(sessionStorage.getItem("file_url_data"))
                  : [];
                let curr = data.filter(
                  (ele) => ele.file_id === res.file_url_data["file_id"]
                );
                if (curr.length > 0) {
                  curr = curr[0];
                  if (curr.is_uploaded > 0) {
                    if (curr.is_uploaded === 1) {
                      let file_id = res.file_url_data["file_id"];
                      uploadToS3FinalizeFunc({
                        file_id: file_id,
                        is_uploaded: true,
                      }).then((res) => {
                        if (res !== null) {
                          if (res.success === true) {
                            clearInterval(interval);
                            checkandConversation(file_id, qText);
                          } else {
                            clearInterval(interval);
                            settyping(false);
                            setfileAnalyseState(0);
                            setqueryText("");
                            setselectedFile(null);
                          }
                        } else {
                          settyping(false);
                          setfileAnalyseState(0);
                          setqueryText("");
                          setselectedFile(null);
                          clearInterval(interval);
                        }
                      });
                    } else {
                      clearInterval(interval);
                      settyping(false);
                      setfileAnalyseState(0);
                      setqueryText("");
                      setselectedFile(null);
                    }
                    sessionStorage.setItem(
                      "file_url_data",
                      JSON.stringify(
                        data.filter(
                          (ele) => ele.file_id !== res.file_url_data["file_id"]
                        )
                      )
                    );
                  }
                }
              }, 1000);
            }
          }
        });
      } else {
        let item = selectedFile;
        setselectedFile(null);
        setconversationHistory((prev) => {
          return [
            ...prev,
            {
              hasFile: item === null ? false : item.id === null ? false : true,
              file: item === null ? null : item.id === null ? null : item.id,
              file_url:
                item === null ? null : item.id === null ? null : item.s3_url,
              file_name:
                item === null ? null : item.id === null ? null : item.name,
              date: new Date(),
              text: qText,
              isAudio: false,
              mode: "outgoing",
            },
          ];
        });

        getResponseForQueryData({
          text: qText,
          file_id: item === null ? null : item.id === null ? null : item.id,
          is_generic: true,
          is_prompt: true,
          is_chat: true,
          text_gen: true,
          conv_id: selectedConversation,
        }).then((res) => {
          if (res !== null) {
            if (res.success) {
              if (selectedConversation === null) {
                setselectedConversation(res.conversation.bot_response.conv_id);
                setrefreshConversations(true);
              }
              setconversationHistory((prev) => {
                return [
                  ...prev,
                  {
                    date: new Date(),
                    text: res.conversation.bot_response.text,
                    isAudio: false,
                    mode: "incoming",
                  },
                ];
              });
            }
          }
          settyping(false);
          setfileAnalyseState(0);
          setqueryText("");
          setselectedFile(null);
        });
      }
    } else if (audioData !== null) {
      let qText = queryText;
      settyping(true);
      setqueryText("");
      if (mainFile !== null) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result.split(",")[1];
          qText = base64Data;
          setAudioData(null);
          setfileAnalyseState(1);
          let file = mainFile;
          setmainFile(null);
          let path = "/";
          setselectedFile(null);
          let convo_data = [...conversationHistory];
          convo_data.push({
            hasFile: true,
            file: "currentNone",
            file_url: URL.createObjectURL(file),
            file_name: file.name,
            date: new Date(),
            text: qText,
            isAudio: true,
            mode: "outgoing",
          });
          setconversationHistory(convo_data);
          uploadToS3InitFunc({
            file_name: file.name,
            file_path: path + file.name,
          }).then(async (res) => {
            if (res !== null) {
              if (res.success === true) {
                let data = sessionStorage.getItem("file_url_data")
                  ? JSON.parse(sessionStorage.getItem("file_url_data"))
                  : [];
                data.push({
                  file_id: res.file_url_data["file_id"],
                  is_uploaded: 0,
                });
                sessionStorage.setItem("file_url_data", JSON.stringify(data));
                setconversationHistory((prev) => {
                  prev = prev.map((ele) => {
                    if (ele.file === "currentNone") {
                      ele.file = res.file_url_data["file_id"];
                    }
                    return ele;
                  });
                  return prev;
                });
                await s3upload(
                  file,
                  res.file_url_data.s3_url_data.url,
                  res.file_url_data.s3_url_data.fields.key,
                  res.file_url_data.s3_url_data.fields["x-amz-algorithm"],
                  res.file_url_data.s3_url_data.fields["x-amz-credential"],
                  res.file_url_data.s3_url_data.fields["x-amz-date"],
                  res.file_url_data.s3_url_data.fields["policy"],
                  res.file_url_data.s3_url_data.fields["x-amz-signature"],
                  res.file_url_data["file_id"],
                  file.name,
                  true
                );
                let interval = setInterval(() => {
                  let data = sessionStorage.getItem("file_url_data")
                    ? JSON.parse(sessionStorage.getItem("file_url_data"))
                    : [];
                  let curr = data.filter(
                    (ele) => ele.file_id === res.file_url_data["file_id"]
                  );
                  if (curr.length > 0) {
                    curr = curr[0];
                    if (curr.is_uploaded > 0) {
                      if (curr.is_uploaded === 1) {
                        let file_id = res.file_url_data["file_id"];
                        uploadToS3FinalizeFunc({
                          file_id: file_id,
                          is_uploaded: true,
                        }).then((res) => {
                          if (res !== null) {
                            if (res.success === true) {
                              clearInterval(interval);
                              checkandConversation(file_id, qText, true);
                            } else {
                              clearInterval(interval);
                              settyping(false);
                              setfileAnalyseState(0);
                              setqueryText("");
                              setselectedFile(null);
                            }
                          } else {
                            settyping(false);
                            setfileAnalyseState(0);
                            setqueryText("");
                            setselectedFile(null);
                            clearInterval(interval);
                          }
                        });
                      } else {
                        clearInterval(interval);
                        settyping(false);
                        setfileAnalyseState(0);
                        setqueryText("");
                        setselectedFile(null);
                      }
                      sessionStorage.setItem(
                        "file_url_data",
                        JSON.stringify(
                          data.filter(
                            (ele) =>
                              ele.file_id !== res.file_url_data["file_id"]
                          )
                        )
                      );
                    }
                  }
                }, 1000);
              }
            }
          });
        };
        reader.readAsDataURL(audioData.blob);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result.split(",")[1];
          qText = base64Data;
          let item = selectedFile;
          setselectedFile(null);
          setconversationHistory((prev) => {
            return [
              ...prev,
              {
                hasFile:
                  item === null ? false : item.id === null ? false : true,
                file: item === null ? null : item.id === null ? null : item.id,
                file_url:
                  item === null ? null : item.id === null ? null : item.s3_url,
                file_name:
                  item === null ? null : item.id === null ? null : item.name,
                date: new Date(),
                text: qText,
                isAudio: true,
                mode: "outgoing",
              },
            ];
          });
          getResponseForQueryData({
            file_id: item === null ? null : item.id === null ? null : item.id,
            is_generic: true,
            is_prompt: true,
            is_chat: true,
            text_gen: true,
            conv_id: selectedConversation,
            audio_flag: true,
            audio_file: base64Data,
          }).then((res) => {
            if (res !== null) {
              if (res.success) {
                if (selectedConversation === null) {
                  setselectedConversation(
                    res.conversation.bot_response.conv_id
                  );
                  setrefreshConversations(true);
                }
                setconversationHistory((prev) => {
                  return [
                    ...prev,
                    {
                      date: new Date(),
                      text: res.conversation.bot_response.text,
                      isAudio: true,
                      mode: "incoming",
                    },
                  ];
                });
              }
            }
            settyping(false);
            setfileAnalyseState(0);
            setqueryText("");
            setselectedFile(null);
          });
          setAudioData(null);
        };
        reader.readAsDataURL(audioData.blob);
      }
    }
  };

  const checkandConversation = (file_id, qText, isAudio = false) => {
    setfileAnalyseState(2);
    checkProcceedFunc(file_id).then((res) => {
      if (res !== null) {
        if (res.success === true) {
          if (res.file_data.is_file_processed) {
            getResponseForQueryData(
              isAudio
                ? {
                    file_id: file_id,
                    is_generic: true,
                    is_prompt: true,
                    is_chat: true,
                    text_gen: true,
                    conv_id: selectedConversation,
                    audio_flag: isAudio,
                    audio_file: qText,
                  }
                : {
                    text: qText,
                    file_id: file_id,
                    is_generic: true,
                    is_prompt: true,
                    is_chat: true,
                    text_gen: true,
                    conv_id: selectedConversation,
                    audio_flag: isAudio,
                  }
            ).then((res) => {
              if (res !== null) {
                if (res.success) {
                  if (selectedConversation === null) {
                    setselectedConversation(
                      res.conversation.bot_response.conv_id
                    );
                    setrefreshConversations(true);
                  }
                  setconversationHistory((prev) => {
                    return [
                      ...prev,
                      {
                        date: new Date(),
                        text: res.conversation.bot_response.text,
                        isAudio: isAudio,
                        mode: "incoming",
                      },
                    ];
                  });
                }
              }
              settyping(false);
              setfileAnalyseState(0);
              setqueryText("");
              setselectedFile(null);
            });
          } else {
            setTimeout(() => {
              checkandConversation(file_id, qText);
            }, 1000);
          }
        }
      }
    });
  };

  const heightRef = useRef(null);
  // const [searchEnabled, setsearchEnabled] = useState(false);

  useEffect(() => {
    if (heightRef.current !== null) {
      var objDiv = heightRef.current;
      objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }
  }, [conversationHistory]);

  useEffect(() => {
    if (heightRef.current !== null && typing) {
      var objDiv = heightRef.current;
      objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }
  }, [typing]);

  //prompt textGeneration
  const [fileData, setfileData] = useState([]);
  const [mainFile, setmainFile] = useState(null);
  const [reloadUpload, setreloadUpload] = useState(true);
  const props = {
    multiple: false,
    onRemove: (file, fileList) => {
      const newFileList = fileData.filter((ele) => ele.uid !== file.uid);
      setfileData(newFileList);
    },
    beforeUpload: (file, fileList) => {
      setfileData([file]);
      return false;
    },
    onChange: (info) => {
      setreloadUpload(false);
      uploadInit(info.file, "/");
      setTimeout(() => {
        setfileData([]);
        setreloadUpload(true);
      }, 10);
    },
    fileData,
  };

  const uploadInit = async (file, path) => {
    setmainFile(file);
  };

  const items = [
    {
      label: (
        <>
          {reloadUpload && (
            <Upload {...props}>
              <span className="d-flex align-items-center">
                <UploadOutlined className="mr-3" style={{ fontSize: "16px" }} />{" "}
                Upload from device
              </span>
            </Upload>
          )}
        </>
      ),
      key: "1",
    },
    {
      label: (
        <>
          <span
            className="d-flex align-items-center"
            onClick={(e) => {
              getFileList([]);
              setpath([]);
              setfileModel(true);
            }}
          >
            <AntCloudOutlined className="mr-3" style={{ fontSize: "16px" }} />{" "}
            Select from drive
          </span>
        </>
      ),
      key: "2",
    },
  ];

  //filemanager

  const [fileModel, setfileModel] = useState(false);
  const [fileList, setfileList] = useState([]);
  const [path, setpath] = useState("");
  const [loading, setloading] = useState(true);
  const [selectedFile, setselectedFile] = useState(null);

  useEffect(() => {
    if (selectedFile !== null) {
      setmainFile(null);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (mainFile !== null) {
      setselectedFile(null);
    }
  }, [mainFile]);

  const selectFile = (item) => {
    setselectedFile(item);
    setfileModel(false);
  };

  const getFileList = async (path) => {
    let pathz = path.join("/");
    if (path.length > 0) {
      pathz = "/" + pathz;
    }
    setloading(true);
    getFilesForSelectionData(pathz)
      .then((res) => {
        if (res !== null) {
          if (res.success === false) {
            setfileList([]);
          } else {
            setfileList(res.file_data);
          }
        }
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  //upload file

  return (
    <div className="dashboard-main-container mt-9 mt-lg-9" id="dashboard-body">
      <div className="container position-relative">
        <span
          style={{
            position: "absolute",
            fontSize: "14px",
            fontWeight: "500",
            top: "-25px",
            left: "5%",
          }}
        >
          Current Chat:
        </span>
        <div className="row">
          <div className="col-11 m-auto heightFull">
            <div
              ref={tourRef4}
              className="col-12  px-14 inputBox position-relative"
              style={{
                marginLeft: "-1rem",
                bottom:
                  selectedConversation === null && !typing ? "50%" : "20px",
              }}
            >
              {selectedConversation === null && !typing && (
                <h4 className="font-weight-bold text-black-50 text-center">
                  What can i help you with?
                </h4>
              )}
              {selectedFile !== null && (
                <Tag
                  closable
                  style={{
                    fontSize: "16px",
                    padding: "6px 14px 24px 14px",
                    marginLeft: "0px",
                    borderRadius: "50px",
                    marginBottom: "-18px",
                    borderBottomLeftRadius: "0px",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(selectedFile.s3_url, "_blank")}
                  color="processing"
                  onClose={(e) => {
                    e.preventDefault();
                    setselectedFile(null);
                    setmainFile(null);
                  }}
                >
                  <span
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <FileFilled
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        marginRight: "6px",
                      }}
                    />
                    {selectedFile.name}
                  </span>
                </Tag>
              )}
              {mainFile !== null && (
                <Tag
                  closable
                  style={{
                    fontSize: "16px",
                    padding: "6px 14px 24px 14px",
                    marginLeft: "0px",
                    borderRadius: "50px",
                    marginBottom: "-18px",
                    borderBottomLeftRadius: "0px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const url = URL.createObjectURL(mainFile);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = mainFile.name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                  }}
                  color="processing"
                  onClose={(e) => {
                    e.preventDefault();
                    setselectedFile(null);
                    setmainFile(null);
                  }}
                >
                  <span
                    style={{ display: "inline-flex", alignItems: "center" }}
                  >
                    <FileFilled
                      style={{
                        fontSize: "14px",
                        marginTop: "0px",
                        marginRight: "6px",
                      }}
                    />
                    {mainFile.name}
                  </span>
                </Tag>
              )}
              <Input.TextArea
                rows={[1, 1]}
                placeholder="Enter your query here..."
                ref={currentCursor}
                style={{
                  height: "45px",
                  fontSize: "15px",
                  padding: "10px 80px 10px 40px",
                  resize: "none",
                  overflow: "hidden",
                  borderRadius: "20px",
                }}
                value={queryText}
                onChange={(e) => {
                  setqueryText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (e.shiftKey) {
                      e.preventDefault();
                      const cursorPos = e.target.selectionStart;
                      const textBeforeCursor = queryText.substring(
                        0,
                        cursorPos
                      );
                      const textAfterCursor = queryText.substring(cursorPos);
                      setqueryText(`${textBeforeCursor}\n${textAfterCursor}`);
                      setTimeout(() => {
                        e.target.selectionStart = cursorPos + 1;
                        e.target.selectionEnd = cursorPos + 1;
                      }, 0);
                    } else {
                      submitText();
                    }
                  }
                }}
              />
              <span
                className=" d-flex align-items-center pl-14"
                style={{ position: "absolute", left: "8px", bottom: "13px" }}
              >
                <span style={{ background: "transparent", marginRight: "3px" }}>
                  {reloadUpload && (
                    <Dropdown
                      trigger={["click"]}
                      menu={{ items }}
                      placement="topRight"
                    >
                      <span
                        className="pl-4"
                        style={{
                          fontSize: "18px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PaperClipOutlined />
                      </span>
                    </Dropdown>
                  )}
                </span>
              </span>
              <span
                className=" d-flex align-items-center pr-14"
                style={{ position: "absolute", right: "10px", bottom: "8px" }}
              >
                <span
                  style={{
                    background: "transparent",
                    marginRight: "3px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Recorder
                    onTranscription={onTranslation}
                    style={{ height: "30px", width: "30px" }}
                    border={false}
                    onStart={() => {
                      setqueryText("");
                    }}
                  />
                </span>
                <span
                  style={{
                    height: "30px",
                    width: "30px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <SendOutlined
                    className=" text-primary"
                    style={{ fontSize: "18px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      submitText();
                    }}
                  />
                </span>
              </span>
            </div>
            <Card bodyStyle={{ paddingBottom: "4px" }}>
              <div className="chat-history" ref={heightRef}>
                {conversationHistory.length > 0 && (
                  <ul className="m-b-0">
                    {conversationHistory.map((ele, index) =>
                      ele.mode === "outgoing" ? (
                        <li className="clearfix" key={index}>
                          <div className="message-data text-right">
                            <span className="message-data-time">
                              {moment(ele.date).format("DD-MM-YYYY hh:mm")}
                            </span>
                            <img
                              src="https://bootdey.com/img/Content/avatar/avatar7.png"
                              alt="avatar"
                            />
                          </div>
                          <div className="message other-message float-right text-left">
                            {ele.hasFile && (
                              <div
                                className="border d-flex py-2 px-3"
                                style={{
                                  minWidth: "200px",
                                  borderRadius: "10px",
                                }}
                              >
                                <PaperClipOutlined
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "6px",
                                    marginRight: "6px",
                                  }}
                                  className="text-primary"
                                />
                                <a
                                  href={ele.file_url}
                                  style={{ height: "25px", overflow: "hidden" }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {ele.file_name}
                                </a>
                              </div>
                            )}
                            {ele.hasOwnProperty("isAudio") && ele.isAudio ? (
                              <div className="AudioPlayerClasss">
                                <AudioPlayer
                                  src={"data:audio/wav;base64," + ele.text}
                                  minimal={true}
                                  width={250}
                                  trackHeight={14}
                                  barWidth={3}
                                  gap={1}
                                  visualise={true}
                                  backgroundColor="transparent"
                                  barColor="#89b3f0"
                                  barPlayedColor="#1677ff"
                                  skipDuration={2}
                                  showLoopOption={true}
                                  showVolumeControl={true}
                                  hideSeekBar={true}
                                  hideSeekKnobWhenPlaying={true}
                                />
                              </div>
                            ) : ele.text.includes(promptTextGen) ? (
                              ele.text
                            ) : (
                              ele.text
                            )}
                          </div>
                        </li>
                      ) : (
                        <li className="clearfix" key={index}>
                          <div className="message-data">
                            <span className="message-data-time">
                              {moment(ele.date).format("DD-MM-YYYY hh:mm")}
                            </span>
                          </div>
                          <div
                            className="message my-message"
                            dangerouslySetInnerHTML={formatText(ele.text)}
                          ></div>
                        </li>
                      )
                    )}
                    {typing && (
                      <li className="clearfix mb-0">
                        <div className="message-data">
                          <span className="message-data-time"></span>
                        </div>
                        <div
                          className="message my-message"
                          style={{ height: "30px" }}
                        >
                          <img
                            src="/assets/image/typing.gif"
                            style={{ height: "35px", marginTop: "-28px" }}
                            alt=""
                          />
                        </div>
                      </li>
                    )}
                    {typing && fileAnalyseState === 1 && (
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          opacity: 0.5,
                        }}
                        className="text-black-50"
                      >
                        Uploading file...
                      </span>
                    )}
                    {typing && fileAnalyseState === 2 && (
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          opacity: 0.5,
                        }}
                        className="text-black-50"
                      >
                        Analysing file...
                      </span>
                    )}
                  </ul>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
      <FileManager
        path={path}
        setpath={setpath}
        fileList={fileList}
        selectFile={selectFile}
        fileModel={fileModel}
        setfileModel={setfileModel}
        loading={loading}
        setloading={setloading}
        getFileList={getFileList}
      />
    </div>
  );
};

Index.propTypes = {
  isAuthenticated: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { s3upload })(Index);
