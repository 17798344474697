import React, { useEffect, useState, useRef } from "react";
import { AudioOutlined } from "@ant-design/icons";
import Recorder from "recorder-js";

function AudioRecorderAndPlayer({ onTranscription }) {
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null); // Recorder.js instance
  const audioContextRef = useRef(null); // Web Audio API Context
  const socketRef = useRef(null); // WebSocket reference
  const [textExtracted, settextExtracted] = useState("");

  useEffect(() => {
    onTranscription(textExtracted);
  }, [textExtracted, onTranscription]);

  useEffect(() => {
    // Initialize the Web Audio API and Recorder.js
    const initRecorder = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const recorderInstance = new Recorder(audioContext);

        recorderInstance.init(stream);
        audioContextRef.current = audioContext;
        setRecorder(recorderInstance);
      } catch (err) {
        console.error("Error initializing recorder:", err);
      }
    };

    // Initialize WebSocket
    const initWebSocket = () => {
      const ws = new WebSocket("wss://core.sigmatext.ai/ws/transcription");
      socketRef.current = ws;

      ws.onopen = () => {
        console.log("WebSocket connection established.");
      };

      ws.onmessage = (event) => {
        settextExtracted((prev) => prev + " " + event.data);
        console.log("Message from server:", event.data);
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = () => {
        console.log("WebSocket closed.");
      };

      return ws;
    };

    initRecorder();
    initWebSocket();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (
        socketRef.current &&
        socketRef.current.readyState === WebSocket.OPEN
      ) {
        socketRef.current.close();
      }
    };
  }, []);

  const startRecording = () => {
    if (!recorder) {
      console.error("Recorder is not initialized");
      return;
    }
    setIsRecording(true);
    recorder
      .start()
      .then(() => console.log("Recording started"))
      .catch((err) => console.error("Error starting recording:", err));
    // Start collecting audio chunks every 5 seconds
    const chunkInterval = setInterval(() => {
      recorder
        .stop()
        .then(({ blob }) => {
          const wavBlob = new Blob([blob], { type: "audio/wav" }); // Ensures valid WAV chunk
          if (socketRef.current?.readyState === WebSocket.OPEN) {
            socketRef.current.send(wavBlob);
          } else {
            console.warn("WebSocket not open. Failed to send chunk.");
          }
          // Restart recorder for the next chunk
          recorder
            .start()
            .catch((err) => console.error("Error restarting recording:", err));
        })
        .catch((err) => console.error("Error stopping recorder:", err));
    }, 5000);

    recorder.chunkInterval = chunkInterval; // Attach interval for cleanup later
  };

  const stopRecording = () => {
    if (!recorder) return;
    setIsRecording(false);
    clearInterval(recorder.chunkInterval); // Clear interval for chunking
    recorder
      .stop()
      .then(({ blob }) => {
        if (blob.size > 0) {
          const wavBlob = new Blob([blob], { type: "audio/wav" });
          if (socketRef.current?.readyState === WebSocket.OPEN) {
            socketRef.current.send(wavBlob);
            console.log("Final audio chunk sent to WebSocket.");
          }
        }
      })
      .catch((err) => console.error("Error stopping recorder:", err));
  };

  return (
    <div>
      <div className="voice-message">
        <span
          onClick={isRecording ? stopRecording : startRecording}
          style={{ cursor: "pointer" }}
        >
          {isRecording ? (
            <span
              className="control-button px-0 pb-0"
              onClick={stopRecording}
              style={{ paddingTop: "9px" }}
            >
              <i
                className="fa fa-stop-circle text-danger"
                style={{ fontSize: "18px" }}
              />
            </span>
          ) : (
            <span
              className="control-button px-0 pb-0"
              onClick={startRecording}
              style={{ paddingTop: "4px", display: "inline-block" }}
            >
              <AudioOutlined style={{ fontSize: "18px" }} />
            </span>
          )}
        </span>
      </div>
      <div>{textExtracted}</div>
    </div>
  );
}

export default AudioRecorderAndPlayer;
